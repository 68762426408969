.hero_section {
    display: flex;
    justify-content: space-between;
    .hero_img {
        width: 40%;
        height: 800px;
        object-fit: contain;
        pointer-events: none;
    }
    .hero_text {
        width: 55%;
        padding-top: 300px;
        h1 {
            display: flex;
            flex-direction: column;
            gap: 0;
            font-size: 85px;
            color: white;
        }
        p {
            padding-top: 35px;
            display: flex;
            flex-direction: column;
            font-family: Whyte_Extra_Light;
            color: var(--light-gray);
            font-size: 18px;
            line-height: 27px;
            width: 90%;
        }
    }
}
@media (max-width:1024px) {
    .hero_section {
        flex-direction: column;
        gap: 20px;
        .hero_img {
            width: 100%;
            height: 400px;
        }
        .hero_text {
            width:100%;
            padding-top: 0;
            h1 {
                font-size: 35px;
            }
            p {
                padding-top: 20px;
                font-size: 15px;
                line-height: 20px;
            }
        }
    }
}

.second_section {
    padding-top: var(--sections-spacing);
    padding-right: 10%;
    h2 {
        color: white;
        font-size: 55px;
        width: 70%;
        font-family: Whyte_Bold;
    }
    p {
        width: 80%;
        color: var(--light-gray);
        font-family: Whyte_Extra_Light;
        font-size: 18px;
        line-height: 27px;
        padding-left:15%;
        margin-top: 30px;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 14%;
            height: 5px;
            background: white;
        }
    }
}
@media (max-width:1024px) {
    .second_section {
        padding-right: 5%;
        h2 {
            font-size: 30px;
            width: 90%;
        }
        p {
            width: 100%;
            font-size: 15px;
            line-height: 20px;
            &::before {
                width: 12%;
            }
        }
    }
}

.icons_section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px;
    margin-top: 40px;
    padding-left: 10%;
    img,
    video {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: contain;
        pointer-events: none;
    }
}
@media (max-width:1024px) {
    .icons_section {
        grid-template-columns: 1fr;
        padding-left: 0;
        img,
        video {
            width: 100%;
        }
    }   
}

.third_section {
    padding-top: var(--sections-spacing);
    h2 {
        position: relative;
        font-size: 80px;
        color: white;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: calc(100% + 10px);
            height: 3px;
            background: white;
            width: 10%;
        }
    }
    .section_content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 55px;
        .text_side {
            width: 25%;
            padding-top: 40px;
            border-top: 5px solid var(--light-gray);
            display: flex;
            flex-direction: column;
            gap: 20px;
            strong {
                color: #007bc9;
            }

        }
        img.section_img {
            width: 70%;
            height: 700px;
            object-fit: cover;
        }
        h3 {
            color: white;
            font-size: 35px;
        }
        p {
            font-family: Whyte_Extra_Light;
            color: var(--light-gray);
            font-size: 16px;
            line-height: 20px;
            width: 90%;
        }
        a {
            color: white;
        }
    }
}
@media (max-width:1024px) {
    .third_section {
        h2 {
            font-size: 50px;
        }
        .section_content {
            flex-direction: column;
            align-items: unset;
            gap: 20px;
            .text_side {
                width: 90%;
                h3 {
                    color: white;
                    font-size: 25px;
                }
                p {
                    width: 100%;
                }
            }    
            img.section_img {
                width: 100%;
                height: 350px;
            }
        }
    }
}

.section_four {
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    align-items: flex-start;
    padding-right: 10%;
    margin-top: var(--sections-spacing);
    .text_side {
        width: 35%;
        padding-top: 40px;
        border-top: 5px solid var(--light-gray);
        display: flex;
        flex-direction: column;
        gap: 20px;
        strong {
            color: #c62828;
        }
        h3 {
            color: white;
            font-size: 25px;
        }
        p {
            font-family: Whyte_Extra_Light;
            color: var(--light-gray);
            font-size: 16px;
            line-height: 20px;
            width: 90%;
        }
        a {
            color: white;
        }
    }
    img.section_img {
        width: 45%;
        object-fit: cover;
        object-fit: contain;
    }
}
.section_five {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 20%;
    margin-top: var(--sections-spacing);
    .text_side {
        width: 30%;
        padding-top: 40px;
        border-top: 5px solid var(--light-gray);
        display: flex;
        flex-direction: column;
        gap: 20px;
        strong {
            color: #007bc9;
        }
        h3 {
            color: white;
            font-size: 25px;
        }
        p {
            font-family: Whyte_Extra_Light;
            color: var(--light-gray);
            font-size: 16px;
            line-height: 20px;
            width: 90%;
        }
        a {
            color: white;
        }
    }
    img.section_img,
    video {
        width: 65%;
        height: auto;
        object-fit: contain;
    }
}
.section_six {
    display: flex;
    padding-right: 20%;
    transform: translateY(-100px);
    margin-bottom: -100px;
    .text_side {
        width: 30%;
        margin-top: calc(100px + 40px) ;
        padding-top: 40px;
        border-top: 5px solid var(--light-gray);
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-left: 5%;
        strong {
            color: #0bbba9;
        }
        h3 {
            color: white;
            font-size: 25px;
        }
        p {
            font-family: Whyte_Extra_Light;
            color: var(--light-gray);
            font-size: 16px;
            line-height: 20px;
            width: 90%;
        }
        a {
            color: white;
        }
    }
    img.section_img {
        width: 55%;
        max-height: 700px;
        object-fit: contain;
    }
}
@media (max-width:1024px) {
    .section_four,
    .section_five,
    .section_six {
        flex-direction: column;
        align-items: unset;
        gap: 20px;
        padding-left: 0;
        transform: translateY(0);
        margin: {
            top: var(--sections-spacing);
            bottom: unset;
        }
        .text_side {
            width: 90%;
            margin: {
                left:0;
                top: 0;
            }
            h3 {
                color: white;
                font-size: 25px;
            }
            p {
                width: 100%;
            }
        }    
        img.section_img {
            width: 100%;
            height: 350px;
        }
    }
    .section_six {
        padding-right: 20px;
    }
}

footer {
    display: flex;
    align-items: center;
    margin-top: 100px;
    padding-bottom: 60px;
    .left_side {
        width: 40%;
        label {
            color: white;
            font-size: 20px;
        }
        .input_container {
            position: relative;
            width: 300px;
            max-width: 90%;
            margin-top: 20px;
            button {
                position: absolute;
                right: 0;
                color: #8b8b8b;
                top: 50%;
                transform: translateY(-50%);
                font-size: 16px;
                background: transparent;
                border: 0;
                cursor: pointer;
                transition: .3s;
                &:hover {
                    color: white;
                    transform: translate(-50%,-50%);
                }
            }
            input {
                width: 100%;
                height: 30px;
                background: none;
                border: 0;
                font-size: 16px;
                color: white;
                transition: .3s;
                border-bottom: 1px solid #8b8b8b;
                &:focus {
                    border-color: white;
                }
            }
        }
        .location {
            margin-top: 60px;
            p {
                color: var(--light-gray);
                font-size: 16px;
                line-height: 20px;
                font-family: Whyte_Extra_Light;
                padding-bottom: 20px;
            }
            a {
                color: white;
            }
        }
    }
    .right_side {
        width: 60%;
        .footer_links {
            display: flex;
            flex-direction: column;
            a {
                color: white;
                font-size: 80px;
                font-family: Whyte_Bold;
                display: flex;
                align-items: center;
                gap: 10px;
                &:hover {
                    .arrow_icon {
                        transform: scale(1);
                        i {
                            transform: translateX(0);
                            &:nth-child(2) {
                                opacity: 0;
                            }
                        }
                    }
                }
                .arrow_icon {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    align-items: center; 
                    border-radius: 50%;
                    background: #e8e8e8;
                    cursor: pointer;
                    transition: .3s;
                    transform: scale(0);
                    overflow: hidden;
                    i {
                        @include toCenter();
                        min-height: 40px;
                        min-width: 40px;
                        font-size: 20px;
                        color: #343434;
                        transform: translateX(-100%);
                        transition: .5s ease-in-out;
                    }
                }
            }
        }
        .copy_right {
            padding-top: 160px;
            color: var(--light-gray);
        }
    }
}
@media (max-width:1024px) {
    footer {
        flex-direction: column;
        align-items: unset;
        gap: 30px;
        .left_side {
            width: 100%;
        }
        .right_side {
            width: 100%;
            .footer_links {
                a {
                    font-size: 50px;
                }
            }
            .copy_right {
                padding-top: 60px;
                font-size: 12px;
            }
        }
    }
}