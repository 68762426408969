/*----------------|
|    Page Style   |
|----------------*/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    outline: none;
    text-decoration: none;
    scroll-behavior: smooth;
}
:root {
    --theme-background: #000000;
    --light-gray      : #e8e8e8;
    --sections-spacing: 200px;
}
@media (max-width:1024px) {
    :root {
        --sections-spacing: 100px;
    }
}
:root {
    --left-nav-width:90px;
    --top-nav-height:90px;
}
body {
    background: var(--theme-background);
    padding-top: calc(var(--top-nav-height) + 10px);
}
section,
footer {
    margin-left: 17.4%;
}
@media (max-width:1024px) {
    section,
    footer {
        margin-left: 0;
    }
    body {
        padding-left: 20px;
        padding-top: calc(var(--top-nav-height) * 3);
    }
}

/*----------------|
|      Fonts      |
|----------------*/
@font-face {
    font-family: Whyte;
    src: url(../fonts/Whyte-Regular.woff2);
}
@font-face {
    font-family: Whyte_Bold;
    src: url(../fonts/Whyte-Bold.woff2);
}
@font-face {
    font-family: Whyte_Light;
    src: url(../fonts/Whyte-Light.woff2);
}
@font-face {
    font-family: Whyte_Extra_Light;
    src: url(../fonts/Whyte-ExtraLight.woff2);
}
* {
    font-family: Whyte;
}
::-webkit-scrollbar {
    display: none;
}

/*----------------|
|      Mixins     |
|----------------*/
@mixin toCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

/*----------------|
|     Classes     |
|----------------*/
.hide_desktop {
    @media (min-width:1024px) {
        display: none;
    }
}
*.hide {
    display: none !important;
}
.white {
    color: white;
}
*.img_contain {
    object-fit: contain !important; 
}
*.auto_height { 
    height: fit-content !important;
}


/*------------------|
|     Navigation    |
|------------------*/
nav#nav {
    height: 90vh;
    width: 95vw;
    z-index: 100;
    position:fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: none;
    &.not_loaded {
        .nav_left {
            .nav_icons {
                display: none;
            }
        }
        .nav_top {
            .links_btn {
                opacity: 0;
                .links li a span{
                    padding-bottom: 7px;
                }
                .right_btn {
                    gap: 60px;
                    .arrow_icon {
                        transform: scale(0);
                    }
                }
            }
        }
    }
    &.nav_loaded {
        .borders {
            .top_border,
            .bottom_border {
                transform: scaleX(1);
            }
            .left_border,
            .right_border {
                transform: scaleY(1);
            }
        }
    }
    .borders {
        div {
            position: absolute;
            transition: 1.5s ease-in;
        }
        .top_border {
            top: 0;
            left: 0;
            width: 100%;
            border-top: 1px solid #343434;
            transform: scaleX(0);
            transform-origin: left;
        }
        .bottom_border {
            bottom: 0;
            left: 0;
            width: 100%;
            border-bottom: 1px solid #343434;
            transform: scaleX(0);
            transform-origin: left;
        }
        .left_border {
            top: 0;
            left: 0;
            height: 100%;
            border-left: 1px solid #343434;
            transform: scaleY(0);
            transform-origin: top;
        }
        .right_border {
            top: 0;
            right: 0;
            height: 100%;
            border-left: 1px solid #343434;
            transform: scaleY(0);
            transform-origin: left;
            transform-origin: top;
        }
    }
    .nav_left {
        pointer-events: all;
        width: var(--left-nav-width);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
        padding-bottom: 20px;
        .nav_icons {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            width: 100%;
            transition: 1s;
            li {
                width: 100%;
                a {
                    @include toCenter();
                    width: 100%;
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 0; 
                        width: 4px;
                        height: 100%;
                        background: var(--light-gray);
                        transition: .3s;
                        transform-origin: left;
                        transform: scaleY(0);
                        transform-origin: bottom;
                    }
                    &:hover {
                        &::before {
                            transform: scaleY(1);
                        }
                    }
                    i {
                        color: var(--light-gray);
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .nav_top {
        pointer-events: all;
        position: relative;
        display: flex;
        height: var(--top-nav-height);
        .logo {
            @include toCenter();
            height: var(--top-nav-height);
            width: var(--left-nav-width);
            .loader-container {
                @include toCenter();
                height: 70%;
                width:  70%;
                border-radius: 50%;
                border: 5px solid var(--light-gray);
                color: var(--light-gray);
                font-family: Whyte_Bold;
                font-size: 20px;
            }
        }
        .links_btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 10%;
            padding-right: 4%;
            width: calc(100% - var(--left-nav-width));
            .links {
                height: 100%;
                display: flex;
                align-items: center;
                gap: 30px;
                li {
                    height: 100%;
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 100%;
                        background: var(--light-gray);
                        height: 4px;
                        bottom: 0;
                        transform: scaleX(0);
                        transition: .3s;
                        transform-origin: left;
                    }
                    &:hover {
                        &::before {
                            transform: scaleX(1);
                        }
                    }
                    a {
                        height: 100%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: column;
                        gap: 3px;
                        transition: .3s;
                        color: var(--light-gray);
                        font-family: Whyte_Bold;
                        span {
                            font-family: Whyte_Light;
                            font-size: 11px;
                            padding-bottom: 0;
                            transition: .2s ease-out;
                        }
                    }
                }
            }
            .right_btn {
                display: flex;
                align-items: center;
                gap: 20px;
                color: var(--light-gray);
                font-family: Whyte_Bold;
                cursor: pointer;
                transition: .6s ease-out;
                &:hover {
                    .arrow_icon {
                        i {
                            transform: translateX(-100%);
                            &:nth-child(1) {
                                opacity: 0;
                            }
                        }
                    }
                }
                .arrow_icon {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    border-radius: 50%;
                    background: #e8e8e8;
                    cursor: pointer;
                    transition: .6s ease-out;
                    transform: scale(1);
                    i {
                        @include toCenter();
                        min-height: 40px;
                        min-width: 40px;
                        color: #343434;
                        transition: .55s ease-in-out;
                    }
                }
            }
        }
    }
}
@media (max-width:1024px) {
    :root {
        --left-nav-width:54px;
        --top-nav-height:54px;
    }
    nav#nav {
        height: 90vh;
        width: 95vw;
        padding: 20px !important;
        background: rgba(0,0,0,.5);
        backdrop-filter: blur(20px);
        transition: .5s;
        &::before {
            position: fixed;
            top: -5vh;
            left: 0;
            height: 100vh;
            width: 100vw;
            background: #050505;
            content: '';
            transition: 1.5s ease-in-out;
        }
        &.closed_menu {
            background: unset;
            backdrop-filter: unset;
            &::before {
                transform: translateY(-100%);
            }
            & > .borders {
                .top_border,
                .bottom_border {
                    transform: scaleX(0);
                }
                .left_border,
                .right_border {
                    transform: scaleY(0);
                }
            }
            .nav_left {
                pointer-events: none;
                .borders {
                    .top_border,
                    .bottom_border {
                        transform: scaleX(0);
                    }
                    .left_border,
                    .right_border {
                        transform: scaleY(0);
                    }
                }
                .nav_icons {
                    opacity: 0;
                    transform: translateX(-40px);
                }
            }
            .nav_top {
                .links_btn {
                    .links {
                        pointer-events: none;
                        opacity: 0;
                        li {
                            a {
                                gap: 80px;
                            }
                        }
                    }
                }
                .logo {
                    border-right: 1px solid #343434;
                }
            }
        }
        .borders.hide_desktop {
            height: calc(100% - 40px);
            width: calc(100% - 40px);
            position: absolute;
            top: 20px;
            left: 20px;
        }
        .nav_left {
            top: 20px;
            left: 20px;
            height: calc(100% - 40px);
            overflow: hidden;
        }
        .nav_top {
            .logo {
                .loader-container {
                    border-width: 3.5px;
                    font-size:14px;
                }
            }
            .links_btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 10%;
                padding-right: 4%;
                width: calc(100% - var(--left-nav-width));
                .links {
                    position: absolute;
                    top: 100%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    height: 75vh;
                    transition: .6s ease-in;
                    li {
                        height: 50px;
                        a {
                            height: 50px;
                            overflow: hidden;
                            font-size: 35px;
                            color: white;
                            transition: 1s ease-in;
                        }
                    }
                }
                .right_btn {
                    margin-left: auto;
                    margin-right:30px;
                    &:hover {
                        .arrow_icon {
                            i {
                                transform: translateX(-100%);
                                &:nth-child(1) {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                    .arrow_icon {
                        display: none;
                    }
                }
                .menu_icon {
                    width: 25px;
                    color: var(--light-gray);
                }
            }
        }
    }
} 